.main-mobile-menu
  $this: &

  bottom: 0
  height: 60px
  left: 0
  right: 0
  justify-content: space-between
  background-color: #fefefe
  border-top: 1px solid $gray-200
  padding: 0 16px
  z-index: 20

  &__link
    width: auto
    min-width: 40px
    padding-top: 4px
    color: $gray-300
    background-color: transparent
    border: 0

    &::before
      content: ''
      display: inline-block
      position: absolute
      top: 0
      margin: 0 auto
      background-color: $green-200
      height: 2px
      border-radius: 0 0 1px 1px
      transform: translateY(-2px)
      transition: transform 300ms ease-in
      left: 0
      right: 0

    &:not(:last-child)
      margin-right: 20px

    &.is-active
      color: $green-200
      &::before
        transform: translateY(0)
        transition: transform 300ms ease-out

    &:hover
      color: $green-200

  &__icon
    color: $gray-300
    margin-bottom: 1px
    color: inherit
    width: 33px
    height: 32px
    transform: scale(0.8)

  &__text
    color: inherit
    line-height: 140%
    font-weight: 500

  &__links
    width: 100%
    justify-content: space-around

  &__buttons
    width: 124px

  &__notification
    &-wrapper
      color: $gray-300
      background-color: transparent

    &-button
      height: auto
      padding: 0
      overflow: visible

    &-badge
      top: -1px!important
      background-color: #E41E3F
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      width: 17px
      height: 17px
      right: -7px!important
      line-height: 17px

  &__menu
    width: 40px
    padding-top: 2px
    background-color: transparent
    color: $gray-300

    &-icon
      width: 24px
      height: 24px
      margin-bottom: 5px
