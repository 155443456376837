.budget-select

  &__button
    width: 87px

  &__content
    min-width: 528px
    padding: 8px 24px 0px
    z-index: 1
    background-color: $white
    top: 50px
    left: 50%
    transform: translateX(-50%)
    box-shadow: 0px 16px 48px $gray-400
    margin-left: 16px
    border-radius: 4px

  &__title
    font-size: 13px
    line-height: 140%
    font-weight: 500
    color: $gray-400
    margin-bottom: 24px

  &__list
    list-style: none
    padding: 0
    display: flex
    justify-content: center

  &__list-item
    &:not(:last-child)
      margin-right: 8px

  &__list-button
    padding: 8px 14px
    margin-top: 4px
    margin-bottom: 4px
    white-space: nowrap
    background-color: $gray-200
    transition: background-color 300ms ease-in

    &:hover,
    &.is-active
      background-color: $green-200
      transition: background-color 300ms ease-out
