.avatar-popover
  z-index: 20
  cursor: default

  &__body
    width: 18rem
    animation-name: DropDownSlide
    animation-duration: .3s
    animation-fill-mode: both
    z-index: 2
    top: 38px
    left: 0
    display: none

  &__avatar
    margin-right: 1rem

  &__scroll
    max-height: 13.5rem
    box-shadow: 0 1rem 3rem rgba(13, 13, 13, 0.18)

  &__bio
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical