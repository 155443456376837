.chat-right-nav-link
  background-color: transparent
  font-size: 16px
  line-height: 140%
  padding: 12px 8px 13px
  color: $gray-400
  transition: color 300ms ease-in
  font-weight: 700

  &::before
    content: ""
    position: absolute
    left: 14px
    right: 14px
    margin: 0 auto
    height: 2px
    bottom: 0
    background-color: $green-300
    transform: translateY(3px)
    transition: transform 300ms ease-in

  &.active
    color: $green-300
    transition: color 300ms ease-out

    &::before
      transform: translateY(0px)
      transition: transform 300ms ease-out
