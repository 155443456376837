.chat-no-messages
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: 1094px
  padding: 20px 20px 47px 20px

  &__link
    padding: 12px 24px
    background-color: $green-200
    border-radius: 4px
    width: fit-content
    color: $black
    font-size: 13px
    font-weight: 500
    transition: background-color 300ms ease-in

    &:hover
      color: $black
      background-color: #89CC7F
      transition: background-color 300ms ease-out

  &__title
    font-size: 24px
    font-weight: 700
    color: $gray-400
    margin-bottom: 40px
    text-align: center

  &__image
    width: 500px
    height: auto
    margin-bottom: 40px
    display: inline-block

    @include r($breakpoint-sm)
      width: 100%
      max-width: 500px
