.search-results-modal
  $this: &

  &__button-wrapper
    padding: 0 24px 24px

  &__close
    top: -42px
    right: -48px
    z-index: 1
    height: 40px
    width: 40px
    padding: 0 3px 2px 0px
    transition: transform 300ms ease-in
    transform: scale(1) rotate(0deg)

    @include r($breakpoint-sm)
      right: 0

    &:hover
      transform: scale(1.2) rotate(90deg)
      transition: transform 300ms ease-out

    &::before,
    &::after
      content: ""
      display: block
      background-color: $black
      height: 2px
      width: 20px
      position: absolute
      top: 19px
      left: 10px

    &::before
      transform: rotate(45deg)

    &::after
      transform: rotate(-45deg)

  &__request,
  &__message
    padding: 12px 20px

  &__request
    margin-bottom: 16px

  &__panel
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: #fff
    z-index: 11
    border-radius: var(--bs-modal-border-radius)
    display: flex
    flex-direction: column

  &__panel-button
    padding-left: 26px
    display: flex
    align-items: center
    position: relative
    margin-bottom: 5px

  &__arrow
    width: 20px
    height: 20px
    left: 23px
    top: 35px
    top: 2px
    left: 0
    position: absolute

    @include r($breakpoint-sm)
      left: 14px
      top: 27px

    &::before,
    &::after
      content: ""
      display: block
      width: 10px
      height: 2px
      background-color: #999
      position: absolute
      top: 12px
      left: 5px
      transform: rotate(45deg)

    &::after
      top: 6px
      transform: rotate(-45deg)

  &__avatar
    margin-right: 1rem

  &__scroll
    max-height: 100%

    .simplebar-content
      padding: 0!important

  &__body
    margin-top: 2rem
    flex: 1 0
    max-height: calc(100% - 78px)

  &__bio
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical

  &__card
    margin-bottom: 1rem
    border: 1px solid #eaeaea
    border-radius: 4px
    padding: 1rem
    display: grid
    grid-template-columns: 1fr 90px
    grid-gap: 1rem
    align-items: center

  &__submit
    height: 34px
    padding: 0