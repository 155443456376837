.location
  $this: &

  &__list
    margin: 0 0 0.875rem 0

  &__input-wrapper
    height: 2.5rem
    margin-top: -0.5rem
    margin-bottom: 1.5rem

    &.is-focused
      #{$this}__icon
        color: $green-300

    &:not(:last-child)
      margin-bottom: 22px

  &__icon
    top: 7px
    left: 11px
    height: 1.5rem
    width: 1.5rem

  &__autocomplete
    height: 2.5rem
    width: 100%

    &-input
      border: 1px solid $gray-200
      height: 100%
      padding: 0 8px 0 45px
      font-size: 13px !important

  &__text
    height: 1.625rem
    margin-right: 1rem

  &__list-item
    margin-bottom: 0.5rem

    &:not(:last-child)
      margin-right: 1rem

      @include r($breakpoint-lg)
        margin-bottom: 6px
