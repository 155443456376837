.login
  $this: &

  min-height: 100vh
  background-color: #F7F7F7
  align-items: center

  @include r(992px)
    align-items: flex-start
    padding: 40px 0

  &__image
    z-index: 0

  &__container
    z-index: 1

  &__row
    min-height: 442px

  &__main
    max-width: 701px
    margin: 0 auto

    @include r(1200px)
      max-width: 526px

    @include r(992px)
      margin-left: 30px
      margin-bottom: 40px

    @include r(576px)
      margin-left: 20px
      margin-right: 20px
      max-width: 309px

  &__logo
    margin-bottom: 42px

  &__title
    color: $black
    font-family: "RecklessNeue", serif
    font-size: 78px
    line-height: 78px
    letter-spacing: -3.0px
    margin: 0

    @include r(1200px)
      font-size: 60px
      line-height: 66px

    @include r(576px)
      font-size: 45px
      line-height: 48px

    span
      font-family: "Graphik", sans-serif
      letter-spacing: 1.4px
      font-size: 72px
      line-height: 66px

      @include r(1200px)
        font-size: 52px
        line-height: 46px

      @include r(576px)
        font-size: 39px
        line-height: 39px

  &__card
    max-width: 391px
    margin-left: 8px
    box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726)
    margin-top: 2px
    border-radius: 8px

    @include r(992px)
      margin: 0 auto 30px

    &-body
      padding: 32px
      padding-top: 31px

  &__form
    &-wrapper
      &:not(:last-child)
        margin-bottom: 23px

  &__forgot-password
    bottom: -45px
    left: 0
    right: 0
    margin: 0 auto
    width: max-content
    color: $gray-400
    background-color: transparent

  &__modal
    width: 391px
    margin-top: 40px

    @include r(576px)
      max-width: 391px
      width: 100%
      margin: 0 auto
      padding: 15px
