.no-results-found
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: 1094px
  padding: 80px 0px 53px 0px
  text-align: center
  background-color: $white

  &__text
    color: $gray-300
    font-size: 13px
    font-weight: 500

  &__title
    font-size: 18px
    font-weight: 700
    color: $gray-400
    margin-bottom: 8px
    text-align: center

  &__image
    width: 244px
    height: auto
    margin-bottom: 104px
    display: inline-block

    @include r($breakpoint-sm)
      width: 100%
      max-width: 500px

  &--with-image
    padding: 102px 20px 47px 20px
