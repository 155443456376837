.notification-dropdown
  margin-right: 12px

  @include r($breakpoint-sm)
    margin-right: 0

  &__title
    font-size: 18px
    line-height: 140%
    font-weight: 700
    color: $black

  &__link
    font-size: 13px
    line-height: 140%
    font-weight: 500
    color: $black
    transition: color 300ms ease-in

    &:hover
      color: $primary
      transition: color 300ms ease-out

  &__badge
    top: 25px!important
    font-size: 13px
    background-color: #E41E3F
    border-radius: 50%
    right: 10px!important

    @include r($breakpoint-sm)
      top: -1px!important
      right: -7px!important

  &__menu
    margin-top: -13px!important
    margin-right: -95px
    width: 500px
    border-radius: 8px
    z-index: 1
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.18)
    padding: 25px 0 0

  &__list
    padding-top: 10px
    padding-bottom: 25px

  &__list-item
    padding: 14px 24px 14px 24px
    background-color: transparent
    transition: background-color 300ms ease-in

    @include r($breakpoint-sm)
      padding: 16px 40px 16px 16px

    &:hover
      background-color: $gray-100
      transition: background-color 300ms ease-out

    &.is-new
      background-color: $green-100

  &__activity-title
    max-width: 345px

  &__active-avatar
    margin-right: 20px

  &__button
    color: $black
    line-height: 140%
    background-color: transparent
    padding: 8px 24px
    font-weight: 500
    transition: background-color 300ms ease-in

    &:hover
      background-color: $green-100
      transition: background-color 300ms ease-out

    &.is-disabled
      pointer-events: none
      color: $gray-400

  .activity-item-action-menu
    right: 27px
    margin-top: 6px

    @include r($breakpoint-sm)
      right: 17px

  .dropdown-menu.show
    left: unset!important
    right: 0!important

    @include r($breakpoint-sm)
      left: 16px!important
      right: 16px!important
      margin: 0 auto
      width: calc(100vw - 32px)
      margin-bottom: 10px

  &__header
    padding: 0 24px

  &__scroll
    max-height: 350px
