.chat-members
  $this: &

  &__heading
    color: $black
    line-height: 140%
    font-size: 16px
    padding: 0 25px
    height: 72px
    display: flex
    align-items: center
    position: relative
    border: none
    font-weight: 700
    background-color: transparent
    width: 100%

    &:hover
      #{$this}__heading-icon
        color: $green-200
        transition: color 200ms ease-out

  &__heading-icon
    color: $gray-400
    position: absolute
    font-size: 24px
    top: 26px
    right: 19px
    transform: rotate(180deg)
    transition: transform 200ms ease-in

  &__add-button
    color: $black
    line-height: 140%
    font-size: 13px
    padding: 0 16px 0 48px
    height: 32px
    display: flex
    align-items: center
    position: relative
    border: none
    font-weight: 500
    background-color: transparent
    width: 100%
    opacity: 1
    transition: opacity 200ms ease-in
    margin-top: 16px

    &:hover
      opacity: 0.7
      transition: opacity 200ms ease-out

  &__add-button-icon
    color: $gray-400
    height: 32px
    width: 32px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    background-color: $gray-200
    position: absolute
    left: 0
    font-size: 20px

  &__body
    display: none
    padding: 0 16px 16px

  &__list-item
    display: flex
    align-items: center
    padding: 16px 0 24px 0

  &__avatar
    margin-right: 16px

  &__info
    display: flex
    flex-direction: column
    justify-content: center

  &__name
    font-size: 13px
    line-height: 140%
    color: $black

  &__scroll
    max-height: 216px

  &.is-opened
    #{$this}__body
      display: block

    #{$this}__heading-icon
      transform: rotate(0deg)
      transition: transform 200ms ease-out
