.main-content
  padding-top: $header-height
  display: flex
  justify-content: center

  @include r($breakpoint-sm)
    padding-top: $header-mobile-height

.navbar-header
  &__notification-dropdown
    display: inline-block

    @include r($breakpoint-sm)
      display: none!important

  &__share
    height: 24px
    align-items: center
    margin-top: 34px

  .layout
    &__loader
      position: fixed
      left: 0
      top: 0
      right: 0
      bottom: 0
      background: $white
      z-index: 1002

    &__loader-spin
      width: 54px
      height: 54px
      margin: 0 auto
      border: 4px solid $green-200
      border-radius: 50%
      border-right-color: transparent
      position: absolute
      top: calc(50% - 27px)
      left: calc(50% - 27px)
      animation: cssload-spin 700ms infinite linear

@keyframes cssload-spin
  to
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
