.places-autocomplete
  $this: &

  &__list
    background-color: $white
    z-index: 1
    position: var(--address-autocomplete-list-position, relative)
    border-radius: 4px
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.18)

  &__list-item
    height: 34px
    padding: 0 25px
    color: $black
    font-size: 13px
    font-weight: 500
    line-height: 140%
    display: flex
    align-items: center
    background-color: transparent
    cursor: pointer
    transition: background-color 300ms ease-in

    &:hover
      background-color: #DBF5D9
      transition: background-color 300ms ease-out

  &__input-input
    border: 1px solid $gray-200
    height: 100%
    padding: var(--address-autocomplete-padding, 0 8px 0 45px)
    font-size: 13px
    height: var(--address-autocomplete-height, 40px)
    border-radius: 4px

  &__scroll
    max-height: 150px
