.property-alert
  $this: &

  left: 0
  right: 0
  top: $header-height
  line-height: 18px
  font-weight: 500
  background-color: $green-200
  box-shadow: none
  color: $black
  padding: 8px 34px
  z-index: 6

  @include r($breakpoint-sm)
    padding-left: 16px
    top: $header-mobile-height

  &__text
    padding-right: 5px

    @include r($breakpoint-sm)
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap

  &__link
    padding: 0 5px
    color: inherit
    text-decoration: underline!important

    &:hover
      text-decoration: none!important

  &__button
    color: inherit
    opacity: 1
    color: inherit!important
    background-color: transparent!important
    box-shadow: none!important
    outline: 0!important
    padding: 1px 0 0 0
    margin: 0 0 -1px 0
    transition: opacity 300ms ease-in

    @include r($breakpoint-sm)
      white-space: nowrap

    &:hover
      opacity: 0.5
      transition: opacity 300ms ease-out

  &__close-button
    opacity: 1
    width: 34px
    height: 34px
    color: $black
    transition: opacity 300ms ease-in
    background-color: inherit!important

    &:hover
      opacity: 0.5
      transition: opacity 300ms ease-out

  &__marquee
    margin: 0 auto

    &:hover
      .property-alert__marquee-item
        animation-play-state: paused

  &__marquee-item

    @include r($breakpoint-sm)
      left: 100%
      animation: marquee 20s linear infinite

@keyframes marquee
  0%
    left: 100%
  100%
    left: -100%
