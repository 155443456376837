.looking-for
  background-color: $gray-100
  width: 100%
  border-radius: $border-radius-lg
  margin-top: 32px

  @include r($breakpoint-md)
    margin-top: 50px

  @include r($breakpoint-sm)
    margin-top: 25px

  &__title
    font-size: 18px
    font-weight: 700
    color: $black
    margin-bottom: 0.5rem

    @include r($breakpoint-md)
      margin-bottom: 6px

  &__legends
    display: flex
    flex-wrap: wrap

    &:not(:last-child)
      margin-bottom: 16px

  &__text
    font-size: 13px
    font-weight: 500
    line-height: 140%
    margin-bottom: 16px

  &__location
    &:not(:last-child)
      margin-right: 1rem
      position: relative

      &::after
        position: absolute
        display: block
        content: "•"
        right: -0.5rem
        top: 0
        bottom: 0
        transform: translateX(50%)
