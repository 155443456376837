.activity-item
  $this: &

  padding: 25px
  min-height: 110px
  &__avatar
    margin-right: 35px

    @include r($breakpoint-sm)
      margin-right: 10px

  &__title
    line-height: 140%
    max-width: 430px
    margin-bottom: 0

    @include r($breakpoint-md)
      max-width: 360px

    @include r($breakpoint-sm)
      font-size: 13px!important

    strong
      color: $black

  &--small
    padding: 0 24px
    min-height: 60px

    #{$this}__avatar
      margin-right: 20px

      margin-bottom: 24px

  &__link
    font-size: 16px
    font-weight: 700
    border: 0
    background-color: transparent
    padding: 0
    color: $black

    &:hover
      color: $black

  &__button
    font-size: 16px
    font-weight: 700
    border: 0
    background-color: transparent
    padding: 0
