.recomend-user
  $this: &

  border-radius: 4px
  border: 1px solid $gray-200
  background-color: $white
  transition: background-color 300ms ease-in

  &__name-div
    display: flex
    gap: .2rem
    align-items: center
    flex-wrap: wrap
    h5
      margin: 0

  &:hover
    background-color: $gray-100
    transition: background-color 300ms ease-out

  &__image
    margin-right: 16px

  &__title
    line-height: 22px
    color: $black

  &__description
    color: $gray-400
    line-height: 18px
    min-height: 18px

  &__button
    width: 100%
    margin-top: 16px
    padding: 7px 0

    &:hover
      background-color: $gray-200

  &:not(:last-child)
    margin-bottom: 24px

  &--message-button
    flex-direction: column
    width: auto
    margin-right: 16px

    #{$this}__image
      margin-right: 0
      margin-bottom: 15px

    #{$this}__description
      min-height: 40px
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      overflow: hidden
      max-height: 40px

    #{$this}__title
      min-height: 22px
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap
