.faq-disclaimer
  padding-top: 24px

  h1
    font-size: 40px
    line-height: 48px
    font-weight: 700
    letter-spacing: -1.5px
    margin-bottom: 23px

  h3
    font-size: 30px
    font-weight: 600
    margin-bottom: 16px

  h5
    font-size: 24px
    font-weight: 600

  h6
    font-size: 20px
    font-weight: 600

  p,
  li,
  dt,
  dd,
  a
    font-size: 16px
    line-height: 24px
    font-weight: 400
    letter-spacing: -0.4px
    margin-bottom: 16px

  a
    margin-bottom: 0
    color: inherit

    &:hover
      color: $primary

  dd,
  li,
  dt
    margin-bottom: 8px

  dd
    padding-left: 24px

  figure
    padding: 49px 0 0 0
    margin-bottom: 69px

  img
    width: 100%
    height: auto

  figcaption
    font-size: 14px
    line-height: 21px
    font-weight: 400
    position: relative
    margin: 15px 0 0 0
    padding: 0 0 0 11px
    letter-spacing: -0.5px

    &::before
      content: "|"
      position: absolute
      left: 0
      top: 0

  strong
    font-size: 20px
    line-height: 28px
    font-weight: 700
    letter-spacing: -0.7px
    margin-bottom: 16px
    word-spacing: 1px

  q
    font-size: 20px
    line-height: 28px
    font-style: italic
    padding-left: 15px
    border-left: 2px solid
    display: block
    margin: 36px 0 31px 0

  h2
    padding-top: 26px
    font-weight: 700
    font-size: 32px
    line-height: 41px
    letter-spacing: -1.4px
    margin-bottom: 19px
