.chat-main
  margin-right: 25px
  position: relative

  @include r($breakpoint-lg)
    margin-right: 0

  &__card
    box-shadow: none
    margin-bottom: 0

  &__loader
    position: absolute

    &-spin
      width: 30px
      height: 30px
      top: calc(50% - 15px)
      left: calc(50% - 15px)
