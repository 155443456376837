.sidebar
  position: absolute
  left: calc(100% + 28px)
  top: 0

  @include r($breakpoint-xl)
    position: relative
    left: auto
    top: auto
    margin-left: 28px

  @include r($breakpoint-lg)
    width: 100%
    margin-left: 0