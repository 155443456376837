.icon {
  width: 18px;
  height: 18px;

  .display-name {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 11px;
    color: white;
  }
}