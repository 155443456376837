.socials-block

  &__list-item
    &:not(:last-child)
      margin-right: 12px

  &__facebook
    background-color: #556EE6
    border-color: #556EE6

    &:hover
      background-color: #556EE6

  &__title
    margin-bottom: 16px
    padding-top: 1px
