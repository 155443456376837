.card-block

  @include r($breakpoint-md)
    margin-bottom: 24px

  @include r($breakpoint-sm)
    margin-bottom: 16px

  &__body
    padding: 24px

  &__title
    line-height: 19px
    color: $black
    font-weight: 600
    margin-bottom: 8px

  &__text
    color: $gray-400
    line-height: 140%
    margin-bottom: 24px
