.search-results-card
  $this: &

  color: $black
  min-height: 165px
  background-color: $white
  cursor: pointer
  border-radius: 4px
  display: grid
  grid-template-columns: 150px 1fr
  border: 1px solid $gray-200
  border-radius: 4px
  transition: border-color 300ms ease-in

  @include r($breakpoint-sm)
    grid-template-columns: 1fr

  &:not(:last-child)
    margin-bottom: 24px

  &:hover
    border-color: $green-200
    transition: border-color 300ms ease-out

  &__type
    text-transform: capitalize

  &__image
    width: auto
    min-width: 100%
    margin-left: 50%
    transform: translateX(-50%)
    object-fit: cover

  &__body
    padding: 16px 16px 15px 16px

  &__counter
    color: $gray-400
    line-height: 140%
    margin-bottom: 4px

  &__top-content
    margin-bottom: 14px

    @include r($breakpoint-sm)
      margin-bottom: 8px

  &__text
    line-height: 140%
    margin-bottom: 4px

  &__image-wrapper
    @include r($breakpoint-sm)
      height: auto!important
      min-height: 150px

  &__title
    line-height: 140%
    font-weight: 600
    margin-bottom: 0

  &__avatar
    margin-left: -28px
    border: 2px solid $white

    @for $i from 20 through 1
      &:nth-child(#{$i})
        z-index: 21 - $i

    img
      width: 100%
      height: 100%

    &:first-child
      margin: 0

  &__avatar-list
    min-width: 32px
    margin-right: 16px

    @include r($breakpoint-sm)
      margin-right: 10px

  &__description
    margin-bottom: 13px
    line-height: 140%

    @include r($breakpoint-sm)
      margin-bottom: 7px

  &__heading
    @include r($breakpoint-sm)
      margin-top: -2px

  &__info-item
    font-weight: 500
    line-height: 140%
    white-space: nowrap
    color: $gray-300
    overflow: hidden
    text-overflow: ellipsis

    &:not(:last-child)
      margin-right: 10px

  &__info-icon
    width: 24px
    height: 24px
    color: $gray-300
    margin-right: 3px

  &__button
    font-weight: 500
    padding: 7px 16px
    color: $gray-400

    &:hover
      background-color: $green-200
      color: $black
      border-color: $green-200

  &--vertical
    display: block
    border: 0

    &:not(:last-child)
      margin-bottom: 0

    #{$this}__image-wrapper
      min-height: 265px
      max-height: 265px

    #{$this}__body
      padding: 21px 24px 16px 24px
      border-width: 0

    #{$this}__top-content
      margin-bottom: 16px

    #{$this}__no-image
      min-height: inherit
      border-right: 0
      border-bottom: 1px solid

  &__loader
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0

  &__spinner
    width: 30px
    height: 30px
    margin: 0 auto
    border: 3px solid $gray-300
    border-radius: 50%
    border-right-color: transparent
    position: absolute
    top: calc(50% - 15px)
    left: calc(50% - 15px)
    animation: cssload-spin 700ms infinite linear

  &__no-image
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    font-size: 75px
    color: $gray-200
    border-right: 1px solid

  &--popover
    #{$this}__top-content
      flex-direction: column-reverse

    #{$this}__heading
      display: flex
      flex-direction: column-reverse
      margin-bottom: 1rem

    #{$this}__counter
      margin-bottom: 0
      margin-top: 4px

@keyframes cssload-spin
  to
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
