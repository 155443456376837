.profile-mobile-modal
  max-width: 282px
  margin-left: auto
  margin-right: auto

  &__content
    padding: 24px

  &__info
    margin-bottom: 10px!important

  &__info-body
    flex-direction: column
    margin-bottom: 4px!important

  &__info-avatar
    margin-bottom: 12px

  .dropdown-divider
    margin-top: 0

  &__close
    top: 24px
    right: 24px
    z-index: 1
    height: 40px
    width: 40px
    padding: 0 3px 2px 0px
    transition: transform 300ms ease-in
    transform: scale(1) rotate(0deg)

    &:hover
      transform: scale(1.2) rotate(90deg)
      transition: transform 300ms ease-out

    &::before,
    &::after
      content: ""
      display: block
      background-color: $black
      height: 2px
      width: 15px
      position: absolute
      top: 19px
      left: 13px

    &::before
      transform: rotate(45deg)

    &::after
      transform: rotate(-45deg)
