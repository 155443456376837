.start-group
  margin-bottom: 23px

  &__icon
    width: 33px
    height: 32px
    color: $white
    transform: scale(0.7)
    filter: brightness(2)

  &__header
    background-color: $green-200
    width: 48px
    height: 48px
    margin-bottom: 18px
    padding: 1px 2px 0 0

  &__button
    padding: 5px 14px
    margin-top: -7px

  &__progress-bar
    border-radius: 4px

  &__progress-text
    line-height: 140%
    font-weight: 500
