.welcome-modal
  background: #FFFFFF
  border-radius: 8px

  padding: 1.5rem

  &__text
    margin-bottom: 2rem

    .second-text
      padding: 0
      margin-top: .5rem

    h2
      font-style: normal
      font-weight: 700
      font-size: 24px
      line-height: 140%

      color: #0D0D0D

    p
      margin: 0
      color: #666666
      padding-right: 20%

      @include r($breakpoint-sm)
        padding-right: 0
        margin-top: 1rem

  &__cards
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1.5rem

    margin-bottom: 1.5rem

    @include r($breakpoint-sm)
      grid-template-columns: 1fr

  &__card
    background: #F7F7F7
    border-radius: 4px

    padding: 1.5rem

    h3
      font-style: normal
      font-weight: 700
      font-size: 18px
      line-height: 140%
      color: #0D0D0D
      margin: 1rem 0

    ul
      margin: 0
      padding-left: 1rem
      li
        font-style: normal
        font-weight: 500
        font-size: 13px
        line-height: 140%
        color: #666666

    svg > rect:first-child
      fill-opacity: 1 !important

  &__description
    p
      margin: 0
      color: #666666
      padding-right: 20%

      @include r($breakpoint-sm)
        padding-right: 0

  &__buttons
    margin-top: 1.5rem
