.members-popover
  z-index: 20

  &__body
    width: 18rem
    animation-name: DropDownSlide
    animation-duration: .3s
    animation-fill-mode: both
    z-index: 2
    top: 0
    left: 0
    display: none

  &__list-item
    background-color: transparent
    transition: background-color 200ms ease-in

    &:hover
      background-color: $gray-200
      transition: background-color 200ms ease-out

  &__avatar
    margin-right: 1rem

  &__scroll
    max-height: 13.5rem
    box-shadow: 0 1rem 3rem rgba(13, 13, 13, 0.18)
