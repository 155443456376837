.chat-delete-modal
  $this: &

  max-width: 375px

  &__modal-header
    border: none
    height: 87px
    padding: 10px 22px 10px 24px

  &__title
    font-weight: 700

  &__body
    padding: 0px 24px 4px 24px

  &__text
    font-size: 16px
    line-height: 140%
    font-weight: 500
