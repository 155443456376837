.activity-item-action-menu
  right: 2px

  &__time
    margin-bottom: 7px
    font-weight: 500
    color: $gray-300
    white-space: nowrap

  &__icon
    font-size: 23px

  &__button
    padding: 0px 6px
    line-height: 28px
    width: 28px
    height: 28px
    border-radius: 4px
    margin-right: -3px
    color: $gray-300

    &:hover,
    &:focus
      color: $primary
      box-shadow: none
      cursor: pointer

  &__link
    line-height: 140%
    padding: 8px 8px 8px 24px

  &__list
    min-width: 116px
    top: 50% !important
    right: -14px !important
    border-radius: 4px
