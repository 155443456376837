.report-modal-select

  &__button
    width: 100%
    white-space: nowrap
    justify-content: space-between

  &__list
    list-style: none
    padding: 0
    display: flex
    justify-content: center
    margin-top: 5px
    border-radius: 4px
    overflow: hidden
    box-shadow: 0px 16px 48px $gray-400
    min-width: 192px
    flex-direction: column
    background-color: $white
    z-index: 21
    width: 100%

  &__list-button
    line-height: 140%
    font-weight: 500
    margin: 0
    border: 0
    background: transparent
    padding: 8px 24px
    text-align: left
    transition: background-color 300ms ease-in
    text-transform: capitalize

    &:hover
      background-color: $green-100
      transition: background-color 300ms ease-out

    &.is-active
      background-color: $green-200
      transition: background-color 300ms ease-out
