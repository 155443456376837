.chat-right-sidebar-component
  min-width: 286px
  width: 286px
  position: relative

  @include r($breakpoint-xl)
    min-width: 250px
    width: 250px

  &__wrapper
    background-color: $white
    border-radius: 4px

    &:not(:last-child)
      margin-bottom: 25px

  &__tab-content
    padding-top: 8px

  &__scroll
    max-height: calc(100vh - 141px)

  &__loader
    position: absolute

    &-spin
      width: 30px
      height: 30px
      top: calc(50% - 15px)
      left: calc(50% - 15px)
