.activity-invite
  margin-top: 15px

  @include r($breakpoint-sm)
      margin-top: 5px

  &__accept
    margin-right: 16px

  &__deny
    background-color: transparent
