.recommended-modal
  $this: &

  &__button-wrapper
    padding: 0 24px 24px

  &__close
    top: -42px
    right: -48px
    z-index: 1
    height: 40px
    width: 40px
    padding: 0 3px 2px 0px
    transition: transform 300ms ease-in
    transform: scale(1) rotate(0deg)

    @include r($breakpoint-sm)
      right: 0

    &:hover
      transform: scale(1.2) rotate(90deg)
      transition: transform 300ms ease-out

    &::before,
    &::after
      content: ""
      display: block
      background-color: $black
      height: 2px
      width: 20px
      position: absolute
      top: 19px
      left: 10px

    &::before
      transform: rotate(45deg)

    &::after
      transform: rotate(-45deg)

  &__request,
  &__message
    padding: 12px 20px

  &__request
    margin-bottom: 16px

  &__body
    padding: 24px

  &__title
    font-weight: 600
    color: $black
    margin-bottom: 16px

  &__description
    font-weight: 500
    color: $gray-400
    margin-bottom: 14px

  &__link
    color: $black
    width: 100%
    border-radius: 5px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    background-color: $green-200
    transition: color 300ms ease-in, background-color 300ms ease-in

    &:hover
      color: $black
      background-color: #89CC7F
      transition: background-color 300ms ease-out

  &__image
    width: 100%
    height: auto
