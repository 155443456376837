.input-field
  $this: &
  width: 100%

  &__input
    height: 100%
    color: $black
    font-size: 16px

    &::placeholder
      color: $gray-300

    &.is-invalid:focus
      box-shadow: none

    &.is-focused
      border-color: $primary
