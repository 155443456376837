.complete-profile

  &__image
    margin-bottom: 24px

  &__progress-wrapper
    margin-bottom: 14px

    @include r($breakpoint-md)
      margin-top: -5px

  &__progress
    background-color: $gray-200
    height: 5px
    margin-right: 16px
    margin-top: 5px
    flex: 1

  &__progress-bar
    border-radius: 4px

  &__progress-text
    line-height: 140%
    font-weight: 500
