.faq-accordion
  padding-top: 4px

  @include r($breakpoint-sm)
    padding-top: 2px

  .accordion-button
    padding: 19px 27px 20px 23px
    box-shadow: none!important

    @include r($breakpoint-sm)
      padding: 20px 20px 20px 16px

    &:not(.collapsed)
      color: #3D7E2E
      background-color: $green-100

  .accordion-body
    line-height: 140%
    padding: 16px 24px
    color: $black

    @include r($breakpoint-sm)
      padding: 15px 16px

  &__body
    p
      line-height: 140%
      color: $black
      margin: 0

      &:not(:last-child)
        margin-bottom: 16px
