.faq
  min-height: 100vh
  position: relative

  @include r($breakpoint-md) 
    padding-top: 48px

  &__main
    max-width: 798px
    margin: 0 auto
    padding: 68px 0 0 0

    @include r($breakpoint-xxl)
      max-width: 700px

    @include r($breakpoint-xl)
      margin-right: 0
      padding-right: 24px
      max-width: calc(100% - 299px)

    @include r($breakpoint-md)
      margin-left: 0
      max-width: unset
      padding-left: 24px

    @include r($breakpoint-sm)
      padding: 2px 0 60px 0
  
  &__crumbs
    position: absolute
    left: 75px
    top: 25px

    &-link
      color: $gray-400
      font-size: 13px
      line-height: 140%
      font-weight: 500
      padding-right: 13px
      margin-right: 8px
      position: relative

      &:hover
        color: $black

      &::after
        content: "/"
        display: block
        position: absolute
        right: 0
        top: 0

    &-text
      color: $gray-300
      font-size: 13px
      line-height: 140%
      font-weight: 500

    @include r($breakpoint-md) 
      left: 17px
      top: 16px
