.roles-step
  justify-content: center 

  &-item
    margin: 12px

    &:not(:last-child)
      margin-right: 12px

  &-button
    min-height: 100%
    display: flex
    flex-direction: column
    align-items: center
