.secondary-button {
  $this: &;

  background-color: $white;
  border: 1px solid $gray-400;
  color: $gray-400;
  transition: color 300ms ease-in, background-color 300ms ease-in;

  &:hover,
  &:active,
  &:focus {
    color: $gray-400;
    background-color: rgba(234, 234, 234, 0.3);
    transition: background-color 300ms ease-out;
  }

  &:active,
  &:focus {
    box-shadow: none;
  }

  // states
  &.is-error {
    color: $tomato;
    border-color: $tomato;

    &:hover {
      border-color: $tomato;
    }
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.3;

    #{$this}__icon {
      opacity: 0.5;
    }
  }
}
