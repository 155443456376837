
.loader
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  background: $white
  z-index: 1002

  &__spin
    width: 54px
    height: 54px
    margin: 0 auto
    border: 4px solid $green-200
    border-radius: 50%
    border-right-color: transparent
    position: absolute
    top: calc(50% - 27px)
    left: calc(50% - 27px)
    animation: cssload-spin 700ms infinite linear

@keyframes cssload-spin
  to
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
