.chat-main
  margin-right: 25px
  overflow: hidden

  @include r($breakpoint-lg)
    margin-right: 0

  &__card
    box-shadow: none
    margin-bottom: 0
