.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.color-black {
  color: $black;
}

.color-gray-300 {
  color: $gray-300;
}

.color-gray-400 {
  color: $gray-400;
}

.background-color-white {
  background-color: $white;
}

.background-color-green-200 {
  background-color: $green-200;
}

.background-color-gray-100 {
  background-color: $gray-100;
}

.background-color-gray-200 {
  background-color: $gray-200;
}

.background-color-gray-300 {
  background-color: $gray-300;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-gray {
  border: 1px solid $gray-200
}

.box-sizing-box {
  box-sizing: border-box;
}

.text-color-primary {
  color: $black;
}

.line-height-140 {
  line-height: 140%;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-medium {
  font-weight: 500;
}

.fw-extra-bold {
  font-weight: 700;
}

.mr-2 {
  margin-right: 0.5rem;
}
