.chat-modal-list
  $this: &

  position: absolute
  left: 0
  top: 54px
  width: 387px
  filter: drop-shadow(0px 16px 48px rgba(0, 0, 0, 0.18))

  &__button
    padding: 16px
    background-color: $white
    transition: background-color 300ms ease-in

    &:hover
      background-color: $gray-100
      transition: background-color 300ms ease-out

  &__avatar
    margin-right: 16px

  &__name-wrapper
    font-weight: 500
    line-height: 140%
    color: $black
    text-align: left
