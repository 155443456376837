.chat-main-conversation
  padding: 0 25px

  @include r($breakpoint-sm)
    padding: 7px 16px!important

  &__day-title-wrapper
    margin-bottom: 28px

    @include r($breakpoint-xxl)
      padding-top: 5px
      margin-bottom: 50px

    @include r($breakpoint-lg)
      margin-bottom: 30px

  &__day-title
    font-weight: 500
    line-height: 140%
    color: $gray-300
    text-transform: uppercase

  &__scroll
    height: 496px
    padding-right: 10px
    margin-right: -10px
    display: flex
    flex-direction: column
    justify-content: flex-end

  &__list
    min-height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-end

  .simplebar-content
    height: 100%
