.main-modal-footer

  &__button
    &:not(:last-child)
      margin-right: 1rem

    &-skip
      opacity: 1
      transition: opacity 300ms ease-in

      &:hover
        opacity: 0.5
        transition: opacity 300ms ease-out

  &.is-disabled
    pointer-events: none
    opacity: 0.5
