.avatar
  $this: &

  background-color: $white

  &--size-xs
    max-width: 32px
    min-width: 32px
    height: 32px

    #{$this}__default-avatar
      width: 24px
      height: 24px

  &--size-s
    max-width: 36px
    min-width: 36px
    height: 36px

    #{$this}__default-avatar
      width: 36px
      height: 36px

  &--size-m
    max-width: 40px
    min-width: 40px
    height: 40px

    #{$this}__default-avatar
      width: 40px
      height: 40px

  &--size-l
    max-width: 56px
    min-width: 56px
    height: 56px

    #{$this}__default-avatar
      width: 40px
      height: 40px

  &--size-xl
    max-width: 64px
    min-width: 64px
    height: 64px

    #{$this}__default-avatar
      width: 54px
      height: 54px

  &--size-xxl
    max-width: 184px
    min-width: 184px
    height: 184px

    #{$this}__default-avatar
      width: 200px
      height: 200px

  &__default-avatar
    background-color: transparent
    position: relative
    border-radius: 100%

  &__image
    width: 100%
    height: 100%
    object-fit: cover


  &.is-no-avatar
    background-color: $gray-200
