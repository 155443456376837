.home

  width: 100%
  padding-top: 74px
  padding-bottom: 50px
  min-height: calc(100vh - 90px)

  @include r($breakpoint-md)
    width: 100%

    .search__link
      display: none

  &__main
    margin: 0 auto
    padding: 0
    min-height: 100%

    display: flex
    flex-direction: column
    max-width: 1131px
    min-width: 695px
    margin: 0 auto
    padding-right: 333px
    width: calc(100% - 48px)!important

    @include r($breakpoint-lg)
      min-width: auto
      width: calc(100% - 48px) !important
      max-width: 900px
      padding-right: 0

    @include r($breakpoint-sm)
      width: calc(100% - 32px) !important

  &__sidebar
    top: 132px
    left: calc(100% + 24px)
    overflow: hidden
    display: grid
    overflow: hidden
    max-width: 309px
    width: 309px
    top: 58px
    bottom: 0
    max-height: unset
    grid-template-rows: 217px 1fr
    position: absolute
    right: 0
    left: unset
    margin-left: 0!important

    @include r($breakpoint-lg)
      display: none

  &__users
    min-width: 100%
    overflow: hidden
    box-sizing: border-box
    background: transparent
    padding: 0
    flex: none
