.chat-main-message
  $this: &

  flex-direction: row-reverse
  margin-bottom: 8px

  @include r($breakpoint-xl)
    margin-bottom: 24px

  @include r($breakpoint-lg)
    margin-bottom: 8px

  @include r($breakpoint-sm)
    margin-bottom: 16px

  &.is-left
    flex-direction: row

    #{$this}__avatar
      margin-left: 0
      margin-right: 8px

    #{$this}__text-wrapper
      background-color: $gray-100
      border-radius: 0 8px 8px 8px

  &__text
    font-weight: 500
    line-height: 140%
    color: $black

  &__avatar
    margin-left: 8px

  &__text-wrapper
    padding: 8px 12px
    max-width: 320px
    background-color: $green-100
    border-radius: 8px 0 8px 8px

  &__name
    color: $gray-300
    position: absolute
    top: -18px
    left: 12px
    line-height: 140%
    font-size: 11px

    @include r($breakpoint-lg)
      display: none

  &__link
    color: $black

    &:hover
      color: $green-300

  &--with-name
    margin-top: 24px
