.modal-button-list
  list-style: none
  margin: 0 0 1.375rem 0

  @include r($breakpoint-lg)
    flex-direction: column

  &__item
    &:not(:last-child)
      margin-right: 24px

      @include r($breakpoint-lg)
        margin-right: 0
        margin-bottom: 6px
