.chat-main-settings

  &__menu
    margin-top: 16px
    margin-right: -7px

  &__icon
    background-color: $gray-100!important

    &:hover
      background-color: $gray-200!important
