.chat-main-header
  border-bottom: 1px solid $gray-100

  &__content
    padding: 25px

    @include r($breakpoint-lg)
      padding-left: 54px

    @include r($breakpoint-sm)
      padding: 16px 16px 16px 40px

  &__user-name
    font-weight: 700
    line-height: 140%
    color: $black
    font-size: 18px
    word-break: break-word

    @include r($breakpoint-sm)
      font-size: 14px

  &__avatar
    margin-right: 16px

    @include r($breakpoint-sm)
      margin-right: 8px

  &__tooltip-wrapper
    height: 34px
    border-radius: 8px
    margin-left: -20px
    margin-top: 10px

  &__tooltip-text
    padding: 0 12px
    font-weight: 500
    line-height: 140%

  &__dropdown-menu
    margin-top: 16px
    margin-right: -7px

  &__icon
    background-color: $gray-100!important

    &:hover
      background-color: $gray-200!important

    &.is-disabled
      background-color: $gray-100!important
      opacity: 0.5
      cursor: default
      pointer-events: none

      &:hover
        background-color: $gray-100!important

  &__exclamation-icon
    transform: rotate(180deg)

  &__image
    width: 100%
    height: 250px
    object-fit: cover
    object-position: center center

    @include r($breakpoint-lg)
      height: 200px

    @include r($breakpoint-sm)
      height: 150px

  &__arrow-button
    width: 20px
    height: 20px
    display: none
    left: 23px
    top: 35px
    background: transparent
    padding: 0

    @include r($breakpoint-lg)
      display: block

    @include r($breakpoint-sm)
      left: 14px
      top: 27px

    &::before,
    &::after
      content: ""
      display: block
      width: 10px
      height: 2px
      background-color: $black
      position: absolute
      top: 12px
      left: 5px
      transform: rotate(45deg)

    &::after
      top: 6px
      transform: rotate(-45deg)
