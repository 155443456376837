.button {
  padding: 13px 20px;
  border-radius: 4px;
  cursor: pointer;

  &__icon {
    margin-left: 4px;
  }

  // sizes
  &--large {
    font-size: 18px;
    line-height: 22px;
  }

  &--medium {
    font-size: 16px;
    line-height: 22.4px;
  }

  &--small,
  &--extra-small {
    font-size: 13px;
    line-height: 18.2px;
  }

  &--small {
    padding: 8px 16px;
  }

  &--extra-small {
    padding: 4px 8px;
  }
}

.icon-button {
  border-radius: 4px;
  cursor: pointer;

  // sizes
  &--medium {
    padding: 15px;
  }

  &--small {
    padding: 11px;
  }

  &--extra-small {
    padding: 8px;
  }
}

.onboarding-modal-open {
  .layout {
    filter: blur(8px)
  }
}

.tooltip-opened {
  overflow: hidden;
  padding-right: 17px;
}
  