.looking-for-legend
  display: flex
  align-items: center
  color: $gray-400
  font-size: 13px
  line-height: 18px
  font-weight: 500
  flex-wrap: wrap

  @include r($breakpoint-md)
    margin: 4px 0

  &__icon
    margin-right: 4px
    color: $gray-300
    width: 24px
    height: 24px