.home__sidebar
  [data-element="experts-modal"]
    @media (min-width: 992px)
      display: none !important

.home__main
  [data-element="experts-modal"]
    @media (min-width: 992px)
      display: none !important

[data-element="experts-modal"]
  margin-bottom: 1.5rem
  display: block !important
  max-height: 34px

  @media (max-width: 576px)
    margin-bottom: 1rem

.sidebar
  [data-element="experts-modal"]
    @media (min-width: 992px)
      display: block !important

[data-element="close-modal"]
  position: absolute
  top: -32px
  right: -100px
  @media (max-width: 660px)
    top: -85px
    right: unset
    left: 52%
    transform: translateX(-50%)

.experts-modal__container
  max-width: 500px

  .welcome-modal__text
    margin-top: 0.5rem

  .go-back-link
    margin-bottom: .5rem
    display: block

  .service-item
    display: flex
    align-items: center
    gap: 1rem
    border-radius: 4px
    background-color: #f7f7f7
    padding: 16px
    border: 1px solid #f7f7f7
    cursor: pointer
    margin-top: 8px

    &:hover
      border: 1px solid #36d93d

    .service-title
      color: #0d0d0d
      font-weight: 600
      font-size: 16px
      font-feature-settings: "clig" off, "liga" off

    .service-description
      color: #666
      font-size: 13px
      margin-top: 4px
      margin-bottom: 0

.agent-card
  align-self: stretch
  background-color: #fff
  display: flex
  max-width: 452px
  padding-bottom: 80px
  flex-direction: column
  line-height: 140%

.agent-info
  justify-content: center
  border-radius: 4px
  border: 1px solid rgba(234, 234, 234, 1)
  background-color: #fff
  display: flex
  width: 100%
  flex-direction: column
  padding: 16px
  position: relative 

.agent-header
  display: flex
  gap: 16px
  margin-bottom: 1rem

.agent-avatar
  width: 64px
  height: 64px
  border-radius: 50%
  object-fit: cover

.agent-details
  display: flex
  flex-direction: column
  margin: auto 0

.agent-name
  color: #0d0d0d
  font-weight: 600
  font-size: 16px

.agent-title
  color: #666
  margin-top: 4px
  font-weight: 500
  font-size: 13px

.agent-description
  color: #0d0d0d
  margin-top: 32px
  font-weight: 500
  font-size: 13px
  line-height: 18px

.agent-actions
  display: flex
  margin-top: 16px
  gap: 8px
  font-size: 13px
  font-weight: 500
  white-space: nowrap
  flex-wrap: wrap

  a 
    display: flex
    gap: 0.5rem
    align-items: center

.email-button
  justify-content: center
  border-radius: 4px
  background-color: #98e38d
  color: #0d0d0d
  padding: 8px 16px
  font-family: Inter, sans-serif
  text-decoration: none
  transition: color 300ms ease-out, background-color 300ms ease-out

  &:hover
    background-color: #89CC7F
    color: inherit


.call-button
  justify-content: center
  border-radius: 4px
  border: 1px solid #666
  color: #666
  padding: 8px 16px
  font-family: Inter, sans-serif
  text-decoration: none

  &:hover
    background-color: #666
    color: white
    transition: color 300ms ease-out, background-color 300ms ease-out

.company-icon
  height: 64px
  position: absolute
  top: 0
  right: 1.5rem
