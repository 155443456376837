.main-menu
  $this: &

  &__link
    width: 100px
    padding-top: 18px
    color: $gray-300
    background-color: transparent
    border: 0

    &::before
      content: ''
      display: inline-block
      position: absolute
      top: 0
      left: 10px
      right: 10px
      margin: 0 auto
      background-color: $green-200
      height: 8px
      border-radius: 0 0 4px 4px
      transform: translateY(-8px)
      transition: transform 300ms ease-in

      @include r(744px)
        left: 0
        right: 0

    &:not(:last-child)
      margin-right: 15px

      @include r(744px)
        margin-right: 5px

    &.is-active
      color: $green-200
      &::before
        transform: translateY(0)
        transition: transform 300ms ease-out

    &:hover
      color: $green-200

    @include r(744px)
      width: 80px

  &__icon
    color: $gray-300
    margin-bottom: 4px
    color: inherit
    width: 33px
    height: 32px

  &__text
    color: inherit
    line-height: 18.2px
    font-weight: 500
      