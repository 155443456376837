.profile-extra
  $this: &

  width: 100%
  position: relative

  &__name-group
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 1rem
    margin-bottom: 8px

    .start-group__button
      margin: 0

  &__name
    font-size: $font-size-extra-large
    font-weight: 700
    line-height: 34px
    color: $black

  &__short-description
    margin-bottom: 8px
    font-size: 16px
    color: $black
    line-height: 140%

    @include r($breakpoint-sm)
      margin-bottom: 7px

  &__edit-button
    top: 0
    right: 24px
    background-color: $gray-100
    opacity: 0
    transition: opacity 250ms ease-in

  &.is-private
    padding-right: 40px

  &.is-public
    padding-right: 225px

  &:hover
    #{$this}__edit-button
      opacity: 1
