.selected-search-criteria
  padding-top: 24px

  &__list
    list-style: none
    flex-wrap: wrap
    margin-top: -4px

  &__list-item
    margin: 4px 0

    &:not(:last-child)
      margin-right: 16px

  &__label
    margin-right: 16px
    color: $gray-400
    line-height: 140%
    font-weight: 500
    white-space: nowrap
