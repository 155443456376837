.profile-card
  $this: &

  margin-bottom: 20px
  padding-bottom: 24px

  @include r($breakpoint-sm)
    padding-bottom: 16px

  &__body
    padding: 0
    position: relative

  &__cover
    position: relative
    background-color: #C4C4C4
    background-size: cover
    background-position: center center
    height: 200px
    border-radius: $border-radius $border-radius 0 0
    margin-bottom: 116px

    @include r($breakpoint-md)
      margin-bottom: 102px

    @include r($breakpoint-sm)
      height: 150px
      margin-bottom: 52px

    &:hover
      #{$this}__cover-button
        opacity: 1

  &__content
    position: relative
    padding: 0 24px
    display: flex
    flex-wrap: wrap

    @include r($breakpoint-md)
      padding: 0 20px

    @include r($breakpoint-sm)
      padding: 0 16px

  &__cover-button
    width: 40px
    height: 40px
    cursor: pointer
    top: 16px
    right: 16px
    position: absolute
    opacity: 0
    transition: opacity 250ms ease-in

  &__file-input
    position: absolute
    opacity: 0
    width: 40px
    height: 40px