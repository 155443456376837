.primary-button {
  $this: &;

  background-color: $green-200;
  border: 0;
  color: $black;
  justify-content: center;
  transition: color 300ms ease-in, background-color 300ms ease-in;

  &:hover {
    color: $black;
    background-color: #89CC7F;
    transition: background-color 300ms ease-out;
  }

  &:active,
  &:focus {
    box-shadow: none;
    color: $black;
    background-color: $green-200;
  }

  // states
  &.is-error {
    color: $white;
    background-color: $tomato;

    &:hover {
      background-color: $tomato;
    }
  }

  &.is-disabled {
    background-color: rgba(234, 234, 234, 0.3);
    border: 1px solid $gray-300;
    color: $gray-300;
    cursor: default;
    opacity: 0.7;

    #{$this}__icon {
      opacity: 0.5;
    }

    &:hover {
      background-color: rgba(234, 234, 234, 0.3);
    }
  }
}
