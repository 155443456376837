.activity
  margin-bottom: 0

  &__list-item
    padding-top: 24px
    padding-bottom: 29px
    padding-left: 0

    @include r($breakpoint-sm)
      padding-right: 28px
      padding-top: 0px
      padding-bottom: 18px

  &__list
    margin-left: -24px
    margin-right: -24px
    @include r($breakpoint-sm)
      margin-top: -8px
      padding-bottom: 26px

  &__button
    top: 28px
    right: 24px
    padding: 7px 16px 6px

    @include r($breakpoint-sm)
      top: unset
      bottom: 17px
      left: 16px
      right: 16px

  &__menu-list
    transition: none !important
