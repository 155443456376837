.chat-main-footer
  $this: &

  padding: 25px
  border-top: 1px solid #F7F7F7

  @include r($breakpoint-sm)
    padding-bottom: 80px
  
  &__input
    line-height: 140%
    font-weight: 500
    color: $black
    height: 50px
    border-radius: 50px
    background-color: $gray-100
    padding: 0 36px 0 16px
    border: 1px solid $gray-100!important

    &:hover,
    &:focus
      border-color: $gray-200!important
      background-color: $gray-100

    &::placeholder
      color: $gray-400

  &__icons
    right: 0
    top: 50%
    transform: translateY(-50%)

  &__icon
    &:not(:last-child)
      margin-right: 5px

  &__upload
    width: 0.1px
    height: 0.1px
    opacity: 0
    position: absolute
    z-index: -10

    &:focus
      & + #{$this}__label
        color: $black

  &__label
    color: $gray-300
    width: 50px
    height: 50px
    cursor: pointer

    &:hover
      color: $black

  &__tooltip-wrapper
    height: 34px
    border-radius: 8px
    margin-left: -20px
    margin-top: 10px

  &__tooltip-text
    padding: 0 12px
    font-weight: 500
    line-height: 140%

  &.is-disabled
    opacity: 0.7
    pointer-events: none
