.profile-menu-dropdonw-header
  $this: &

  position: relative
  font-weight: 500
  padding-right: 18px

  &:before
    content: ''
    position: absolute
    top: 50%
    right: 0
    width: 0
    height: 0
    margin-top: -2px
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    border-top: 5px solid $gray-300


  &__arrow
    color: $gray-300

    @include r(744px)
      display: none

  &__avatar
    position: relative
    margin-right: 0.5rem

    @include r(744px)
      margin-right: 0

    &:before
      content: ''
      position: absolute
      top: -3px
      left: -3px
      right: -3px
      bottom: -3px
      background-color: $green-200
      opacity: 0
      border-radius: 50%

  &.is-active
    color: $green-200
    #{$this}__avatar: before
    opacity: 1

  &__name
    @include r(744px)
      display: none
