.about-profile-section
  box-shadow: $box-shadow-100
  position: relative

  &__edit
    right: 24px
    top: 24px
    background-color: $gray-100

  &__title
    font-weight: 600
    height: 40px

  &__description,
  &__title
    color: $black