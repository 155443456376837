.budget-range
  grid-template-columns: 52px 1fr 52px
  grid-column-gap: 15px
  margin-bottom: 2rem

  &__range
    border-radius: 2px
    height: 4px

  &__button
    height: 14px
    width: 14px
    border: 2px solid $white
    z-index: 1!important

  &__line
    top: 0
    bottom: 0
    z-index: 0
