.single-select
  .single-select
    &__menu
      width: auto
      min-width: 100%
      margin-top: 11px
      box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.18)
      overflow: hidden
    
    &__menu-list
      padding: 0

    &__option
      white-space: nowrap
      font-size: 11px
      line-height: 140%
      padding: 8px 24px


      &--is-focused
        background-color: $green-100

      &--is-selected
        background-color: $green-200
        color: $black

    &__control
      font-size: 13px
      border-radius: 4px
      border: 1px solid $gray-200
      line-height: 140%
      font-weight: 500
      min-height: 34px
      height: 34px
      color: $black
      cursor: pointer

      &:hover,
      &--is-focused
        border-color: $primary

      &--is-focused
        box-shadow: none

  .single-select__placeholder
    color: $gray-400
    white-space: nowrap

  .single-select__indicator
    padding: 8px 12px 8px 2px

    &-separator
      display: none

    svg
      width: 14px
      height: 14px
