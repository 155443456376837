.modal-pill-list
  list-style: none
  margin: 0 0 1.375rem 0
  max-width: 600px
  margin: -0.5rem auto 1rem auto

  &__item
    margin: 0.5rem

  &__pill
    background-color: $gray-200 

    &:focus
      background-color: $gray-200

    &:hover,
    &.is-active
      background-color: $green-200
