.chat-tab-pane
  $this: &

  &__list
    border-radius: 4px

  &__list-item

    &:hover
      #{$this}__link
        background-color: $white

    &.active

      #{$this}__link
        background-color: $gray-200

      #{$this}__description
        font-weight: 700

  &__link
    padding: 16px!important
    cursor: pointer

  &__avatar
    margin-right: 16px

  &__name
    font-weight: 700
    line-height: 140%

  &__description
    color: $gray-300
    font-weight: 500
    line-height: 140%

    .chat-main-message__link
      padding: 0
      border: 0
      color: $gray-300
      text-overflow: ellipsis
      width: 100%
      overflow: hidden
      pointer-events: none
      background: transparent!important

  &__status
    margin: -3px 0 0 5px

  &__time
    color: $gray-300

  &__scroll
    max-height: calc(100vh - 330px)
