.profile-editable-item
  $this: &

  &__title
    color: $black

  &__control
    height: 34px

    &-input
      color: $action-100
      font-size: 13px
      font-weight: 500
      border: 1px solid #ced4da

  &.is-black
    #{$this}__control-input
      color: $black