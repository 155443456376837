.recommended-users-slider
  display: none

  @include r($breakpoint-lg)
    display: block

  &__slider-wrapper
    margin-right: -24px

  &__slider-arrow
    right: 1rem
    top: 50%
    margin-top: -28px
    width: 56px
    height: 56px
    background-color: $white
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.18)
    z-index: 1
    transform: scale(1)
    transition: transform 300ms ease-in

    &:hover
      transform: scale(1.2)
      transition: transform 300ms ease-out
    
    &::before,
    &::after
      content: ''
      width: 15px
      height: 3px
      position: absolute
      left: 21px
      top: 23px
      background-color: #323232
      transform: rotate(45deg)

    &::after
      transform: rotate(-45deg)
      top: 32px

    @include r($breakpoint-sm)
      display: none

    &-prev
      left: 1rem

      &::before
        transform: rotate(-45deg)

      &::after
        transform: rotate(45deg)

  &.is-shown-on-desktop
    display: block
