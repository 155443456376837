.step

  &__heading
    margin-bottom: 0.75rem

  &__description
    margin-bottom: 2rem

    @include r($breakpoint-lg)
      margin-bottom: 1.5rem
