.sign-up-modal
  $this: &

  max-width: 391px
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726)
  margin-bottom: 0px
  margin-top: 3px
  border-radius: 8px

  &__body 
    padding: 32px

  &__form
    &-wrapper
      &:not(:last-child)
        margin-bottom: 23px

      &-name
        display: grid
        grid-template-columns: 1fr 1fr
        grid-column-gap: 24px

    &-control
      font-size: 16px
      line-height: 140%
      color: $black

      &::placeholder
        color: $gray-300

      &.is-invalid:focus
        box-shadow: none

      &.is-focused
        border-color: $primary

  &__submit-button
    width: 100%

  &__socials
    margin: 0

    &-item
      &:not(:last-child)
        margin-right: 12px

    &-facebook
      background-color: #556EE6
      border-color: #556EE6

      &:hover
        background-color: #556EE6

  &__socials-title
    margin-bottom: 16px
    padding-top: 1px

  &__forgot-password
    position: absolute
    bottom: -45px
    left: 0
    right: 0
    margin: 0 auto
    width: max-content
    color: $gray-400
