.chat-tasks-action-menu
  right: 20px
  z-index: 1
  top: 16px

  &__time
    margin-bottom: 7px
    font-weight: 500
    color: $gray-300

    @include r($breakpoint-sm)
      display: none

  &__icon
    font-size: 18px

  &__button
    padding: 0px 6px
    line-height: 28px
    width: 28px
    height: 28px
    border-radius: 4px
    margin-right: -3px
    color: $gray-300

    &:hover,
    &:focus
      color: $primary
      box-shadow: none

  &__item
    line-height: 140%
    padding: 8px 8px 8px 24px

    &:hover
      color: $green-300
      background-color: transparentize($green-200, 0.9)

  &__menu
    min-width: 116px
    top: 50% !important
    left: -80px !important
    border-radius: 4px