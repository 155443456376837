.promo-banner
  $this: &

  &__card
    color: $black
    background-color: $white
    border-radius: 6px

  &__image
    min-width: 100%
    height: 265px

    @include r($breakpoint-sm)
      height: auto

  &__body
    padding: 24px
    border: 1px solid $gray-200
    border-top: 0

  &__text
    line-height: 140%
    margin-bottom: 4px
    margin-bottom: 16px

  &__title
    line-height: 140%
    font-weight: 600
    margin-bottom: 16px

  &__link
    font-weight: 500
    height: 48px

  &__close
    top: -42px
    right: -48px
    z-index: 1
    height: 40px
    width: 40px
    padding: 0 3px 2px 0px
    transition: transform 300ms ease-in
    transform: scale(1) rotate(0deg)

    &:hover
      transform: scale(1.2) rotate(90deg)
      transition: transform 300ms ease-out

    &::before,
    &::after
      content: ""
      display: block
      background-color: $black
      height: 2px
      width: 20px
      position: absolute
      top: 19px
      left: 10px

    &::before
      transform: rotate(45deg)

    &::after
      transform: rotate(-45deg)

    @include r($breakpoint-sm)
      top: -47px
      right: 0

  &__content
    border: 0
