.chat-tasks-block
  padding-top: 25px

  #experts-modal-chatroom
    display: block !important

  &__heading
    color: $black
    line-height: 140%
    padding-left: 24px
    margin-bottom: 22px
    font-weight: 700
