.modal-button
  $this: &

  border: 2px solid $gray-100
  padding: 13px 16px 15px
  width: 8.125rem
  min-height: 8.375rem
  outline: 0!important
  transition: border-color 300ms ease-in, background-color 300ms ease-in

  &:focus
    background-color: $gray-100
    border: 2px solid $gray-100
    color: $black
    box-shadow: none!important
    outline: 0!important

  &:hover
    border-color: $gray-100
    background-color: $gray-200
    color: $black
    transition: background-color 300ms ease-out

  &.is-active
    border-color: $green-300
    background-color: $gray-100
    color: $black
    transition: border-color 300ms ease-out

  &:active,
  &:focus,
  &.is-active
    box-shadow: none

  @include r($breakpoint-lg)
    width: 100%
    display: flex
    min-height: auto
    align-items: center
    padding: 0.5rem
    flex-direction: row!important

  &__icon-wrapper
    width: 4rem
    height: 4rem

    @include r($breakpoint-lg)
      width: 2rem
      height: 2rem
      margin-bottom: 0!important
      margin-right: 1rem!important
      margin-left: 0!important

  &__icon
    width: 30px
    height: 30px

    @include r($breakpoint-lg)
      transform: scale(0.5)

  &--description,
  &.is-large
    width: 233px
    padding-top: 39px
    min-height: 233px
    display: flex
    flex-direction: column
    align-items: center

    #{$this}__title
      display: block
      margin-bottom: 15px

    @include r($breakpoint-lg)
      padding-top: 8px
      min-height: auto
      width: 100%
      flex-direction: row

      #{$this}__title
        margin-bottom: 0

      #{$this}__description
        display: none

  &.is-large
    min-height: 200px

    @include r($breakpoint-lg)
      min-height: auto
