.chat-delete-modal-footer
  display: flex
  justify-content: space-between
  padding: 0px 17px 20px 19px

  &__button
    border: 0
    display: flex
    align-items: center
    width: 153px
    justify-content: center
    font-size: 13px
    line-height: 140%
    font-weight: 500
    border-radius: 4px
    height: 34px

    &:hover
      opacity: 0.7

    &--cancel
      background-color: $gray-200

    &--delete
      background-color: #EB5757
      color: $white
