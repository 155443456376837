.report-modal
  $this: &

  max-width: 375px

  &__modal-header
    border: none
    height: 87px

  &__button
    border: 0

    &.is-disabled
      background-color: $gray-200
      color: $gray-300
      opacity: 1
      pointer-events: none
      border: 0

  &__title
    color: $black
    font-weight: 700

  &__input
    height: 34px

  &__input-input
    font-size: 13px
    border-color: #CED4DA

  &__short-contacts
    color: $gray-400
    margin-right: 16px
    line-height: 22px
    margin-bottom: 0

  &__mask
    height: 34px
    border: 1px solid $gray-200
    border-radius: 4px
    display: flex
    align-items: center
    padding: 0 5px

    &:hover
      border-color: $green-200

  &__list
    position: absolute
    left: 0
    top: 54px
    width: 387px
    filter: drop-shadow(0px 16px 48px rgba(0, 0, 0, 0.18))

  &__list-button
    padding: 16px
    background-color: $white
    transition: background-color 300ms ease-in

    &:hover
      background-color: $gray-100
      transition: background-color 300ms ease-out

  &__avatar
    margin-right: 16px

  &__name-wrapper
    font-weight: 500
    line-height: 140%
    color: $black
    text-align: left

  &__name
    color: $gray-300
    margin-top: 4px

  &__pill
    background-color: transparent
    align-items: center

  &__pill-icon
    margin-left: 5px
    color: $gray-400
    font-size: 9px
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    
    &:hover
      color: $black

    &::before
      z-index: 1

    &::after
      content: ""
      display: block
      position: absolute
      width: 16px
      height: 16px
      left: 50%
      top: 50%
      z-index: 0
      border-radius: 50%
      background-color: $gray-100
      transform: translate(-50%, -50%)

  &__button-text
    display: flex
    align-items: center

  &__button-icon
    font-size: 16px
    margin-right: 6px

  &.is-error
    #{$this}__input-wrapper
      margin-bottom: 20px

    #{$this}__button
      background-color: $red
      color: $white
