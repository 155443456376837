.explore
  width: 100%
  padding-top: 74px
  padding-bottom: 50px

  @include r($breakpoint-md)
    width: 100%

  &__main
    margin: 0 auto
    padding: 0
    min-height: 100%

    display: flex
    flex-direction: column
    max-width: 1131px
    min-width: 695px
    margin: 0 auto
    padding-right: 333px
    width: calc(100% - 48px)!important

    @include r($breakpoint-lg)
      min-width: auto
      width: calc(100% - 48px) !important
      max-width: 900px
      padding-right: 0

    @include r($breakpoint-sm)
      width: calc(100% - 32px) !important

  &__sidebar
    top: 132px
    left: calc(100% + 24px)
    overflow: hidden
    display: grid
    overflow: hidden
    max-width: 309px
    width: 309px
    top: 58px
    bottom: 0
    max-height: unset
    grid-template-rows: 217px 1fr
    position: absolute
    right: 0
    left: unset
    margin-left: 0!important

    @include r($breakpoint-xxxl)
      position: absolute
      right: 0
      left: unset
      margin-left: 0!important

    @include r($breakpoint-lg)
      display: none

  &__users
    min-width: 100%
    overflow: hidden

  &__loader
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    background: $white
    z-index: 1002

  &__loader-spin
    width: 54px
    height: 54px
    margin: 0 auto
    border: 4px solid $green-200
    border-radius: 50%
    border-right-color: transparent
    position: absolute
    top: calc(50% - 27px)
    left: calc(50% - 27px)
    animation: cssload-spin 700ms infinite linear

  &__users
    min-width: 100%
    overflow: hidden
    box-sizing: border-box
    background: transparent
    padding: 0
    flex: none

@keyframes cssload-spin
  to
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
