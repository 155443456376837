.chat-left-sidebar
  min-width: 387px
  width: 387px
  margin-right: 79px
  z-index: 25

  @include r($breakpoint-xxl)
    min-width: 313px
    width: 313px
    margin-right: 41px

  @include r($breakpoint-xl)
    min-width: 290px
    width: 290px
    margin-right: 24px

  @include r($breakpoint-lg)
    position: absolute
    left: 16px
    right: 16px
    margin: 0 auto
    width: auto
    top: 109px
    z-index: 25
    background-color: $gray-100
    bottom: 0

  @include r($breakpoint-sm)
    left: 0
    right: 0
    top: 60px
    bottom: 60px
    padding: 16px

  &__header
    margin-bottom: 23px

  &__title
    line-height: 140%
    font-weight: 700
    color: $black

  &__tooltip-wrapper
    height: 34px
    border-radius: 8px
    margin-left: -20px
    margin-top: 10px

  &__tooltip-text
    padding: 0 12px
    font-weight: 500
    line-height: 140%

  &__dropdown-menu
    margin-top: 16px
    margin-right: -7px

  &__icon
    background-color: $white!important

    &:hover
      background-color: $gray-200!important

  &__search
    height: 40px

  &__search-input
    border-radius: 50px
    padding: 0 30px 0 37px
    border-color: transparent
    font-size: 13px!important
    height: 100%

    @include r($breakpoint-sm)
      padding-left: 30px

    &:hover
      border-color: $green-300

  &__search-icon
    top: 13px
    left: 11px
    font-size: 16px
    color: $gray-400

  &__close-icon
    right: 12px
    top: 9px
    cursor: pointer
    color: $gray-400

    &:hover
      color: $black

  &__search-wrapper
    margin-bottom: 24px
