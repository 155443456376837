.onboarding-tooltip
  inset: 0
  background-color: #000000ad
  z-index: 1002

  &__body

    .tooltip-arrow::before
      border-top-color: #fff!important
      border-width: 0px 5px 9px 5px

    &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before
      border-bottom-color: #fff!important
      border-width: 0px 5px 9px 5px

  &__content
    padding: 0
    max-width: 20rem

  &__image
    width: 100%
    height: auto

  &__button
    width: calc(100% - 1rem)
