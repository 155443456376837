.modal-upload
  border: 2px dashed #CED4DA
  border-radius: 4px
  padding: 8px

  &.is-drag
    opacity: 0.7

  &.is-load
    opacity: 0.7
    pointer-events: none

  &__upload
    width: 0.1px
    height: 0.1px
    opacity: 0
    position: absolute
    z-index: -10
    z-index: 1
    width: 100%
    height: 100%
    left: 0
    top: 0
    cursor: pointer

  &__label
    height: 120px
    cursor: pointer
    flex-direction: column
    padding: 3px 0 0 0

  &__label-text
    line-height: 140%
    color: #495057
    margin-top: 18px

  &__icon
    font-size: 50px
    color: #EAEAEA
    line-height: 50px
