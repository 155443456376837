.main
  max-width: 798px
  width: 100%
  padding-top: 74px
  padding-bottom: 50px
  margin-right: 70px
  position: relative

  @include r($breakpoint-xxl)
    max-width: 695px
    min-width: 695px

  @include r($breakpoint-xl)
    display: flex
    max-width: 100%
    margin-right: 24px
    margin-left: 24px

  @include r($breakpoint-lg)
    max-width: 100%
    flex-direction: column

  @include r($breakpoint-md)
    padding-top: 50px
