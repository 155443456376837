.primary-icon-button {
  $this: &;

  background-color: $green-200;
  border: 0;
  color: $white;
  transition: color 300ms ease-in, background-color 300ms ease-in;

  &:hover,
  &:focus {
    background-color: $black;
    transition: background-color 300ms ease-out;
  }

  // states
  &.is-error {
    background-color: $tomato;

    &:hover {
      background-color: $tomato;
    }
  }

  &.is-disabled {
    background-color: rgba(234, 234, 234, 0.3);
    color: $gray-300;
    cursor: default;

    // #{$this}__icon {
    //   opacity: 0.5;
    // }

    &:hover {
      background-color: rgba(234, 234, 234, 0.3);
    }
  }
}
