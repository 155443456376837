.tertiary-button {
  $this: &;

  background-color: $white;
  border: 0;
  color: $black;
  transition: color 300ms ease-in, background-color 300ms ease-in;

  &:hover,
  &:active,
  &:focus {
    background-color: $gray-100;
    transition: background-color 300ms ease-out;
  }

  &:active,
  &:focus {
    box-shadow: none;
  }

  // states
  &.is-error {
    color: $tomato;

    &:hover,
    &:focus {
      background-color: $white;
    }
  }

  &.is-disabled {
    cursor: default;
    color: rgba(153, 153, 153, 0.3);
    pointer-events: none;
  }
}
