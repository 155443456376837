.choose-room-item
  display: flex
  cursor: pointer

  &__name
    color: $black
    line-height: 18px

  &__count
    color: $gray-300
    line-height: 18px

  &__members
    min-width: 60px

  &:hover,
  &.is-selected
    background-color: $gray-100