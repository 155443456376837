@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

@font-face {
  font-family: "Graphik";
  src:url("../../../fonts/Graphik-Regular.eot");
  src:url("../../../fonts/Graphik-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../../fonts/Graphik-Regular.woff") format("woff"), url("../../../fonts/Graphik-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RecklessNeue";
  src:url("../../../fonts/RecklessNeue-Regular.eot");
  src:url("../../../fonts/RecklessNeue-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../../fonts/RecklessNeue-Regular.woff") format("woff"), url("../../../fonts/RecklessNeue-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
} 
