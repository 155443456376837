.form-step
  &-description
    margin-bottom: 1rem

  &__input
    margin-bottom: 1.5rem

  &__input-input
    font-size: 13px
    border-color: $gray-200

  &__textarea
    height: 101px
    border: 1px solid $gray-200
    font-size: 13px
    line-height: 140%
    color: $black
    padding: 8px 12px
    border-radius: 4px
    margin-bottom: 24px

    &.is-focused
      border-color: $green-300

    &::placeholder
      color: $gray-300
