.upload-profile-avatar
  $this: &

  border: 5px solid $white
  position: absolute
  top: 103px
  left: 19px
  border-radius: 50%
  z-index: 1

  @include r($breakpoint-sm)
    bottom: 0
    top: 50px
    height: fit-content
    left: 50%
    border-radius: 50%
    transform: translateX(-50%)

    .avatar
      max-width: 124px!important
      min-width: 124px!important
      height: 124px!important

  &__action
    position: absolute
    top: -15px
    right: 57px
    opacity: 0
    transition: opacity 250ms ease-in

  &__input
    position: absolute
    width: 40px
    height: 40px
    opacity: 0
    z-index: 1
    cursor: pointer

  &:hover
    #{$this}__action
      opacity: 1