.secondary-icon-button {
  $this: &;

  color: $green-200;
  border: 1px solid $green-200;
  background-color: $white;
  transition: color 300ms ease-in, background-color 300ms ease-in;

  &:hover,
  &:focus {
    color: $green-200;
    border: 1px solid $green-200;
    background-color: $gray-100;
    transition: background-color 300ms ease-out;
  }

  // states
  &.is-error {
    border-color: $tomato;
    color: $tomato;

    &:hover {
      background-color: $white;
      color: $tomato;
      border-color: $tomato;
    }
  }

  &.is-disabled {
    border-color: rgba(153, 153, 153, 0.3);
    color: rgba(153, 153, 153, 0.3);
    cursor: default;
    pointer-events: none;
  }
}
