.profile-menu-dropdown
  height: 52px
  margin: auto 0

  &__body
    min-width: 274px
    max-width: 274px
    display: none


  &__title
    color: $black

  &__link
    line-height: 18px
    color: $gray-400