.chat-link-preview
  $this: &

  display: flex
  padding: 16px

  &:hover
    background-color: $gray-100

  &__image
    width: 40px
    height: 40px
    border-radius: 4px
    margin-right: 8px

  &__no-image
    width: 40px
    height: 40px
    min-width: 40px
    border-radius: 4px
    margin-right: 8px
    background-color: $gray-100

  &__content
    overflow: hidden
    width: 100%

  &__title
    font-size: 13px
    line-height: 18px
    font-weight: 500
    color: $black
    margin-bottom: 6px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    display: block

  &__url
    font-size: 11px
    line-height: 15px
    color: $black
    margin: 0
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    display: block
