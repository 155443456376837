.search-results

  &__wrapper
    @include r($breakpoint-sm)
      padding: 16px

  &__header-text
    font-weight: 500
    color: $black

  &__counter
    color: $green-200
    margin-right: 5px

  &__list
    margin-bottom: 57px

  &__button
    margin: 0 auto
    left: 0
    right: 0
    width: fit-content
    bottom: 24px

  &__header
    margin-bottom: 24px

    @include r($breakpoint-sm)
      margin-bottom: 16px

  &__sort
    min-width: 102px

    .single-select__control
      font-size: 11px
      min-height: 24px
      height: 24px

    .single-select__indicator
      padding: 0 8px 4px 8px
