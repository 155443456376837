.investment-card
  $this: &

  color: $black
  width: 269px
  background-color: $white
  cursor: pointer
  transition: background-color 300ms ease-in
  border: 1px solid $gray-200
  max-width: calc(100% - 20px)
  width: auto

  @include r($breakpoint-md)
    width: auto
    margin-right: 16px!important

  &:hover
    background-color: $gray-100
    transition: background-color 300ms ease-out

    #{$this}__button
      background-color: $green-200
      color: $black
      border-color: $green-200

  &__image
    min-height: 125px
    height: 125px!important
    object-fit: cover

  &__no-image
    min-height: 125px
    height: 125px !important
    display: flex
    align-items: center
    justify-content: center
    font-size: 75px
    color: $gray-200

  &__body
    padding: 17px 15px 14px 15px
    border-top: 1px solid $gray-200

  &__text
    line-height: 140%
    margin-bottom: 4px
    height: 31px
    -webkit-line-clamp: 2
    overflow: hidden
    text-overflow: ellipsis

  &__title
    line-height: 140%
    font-weight: 600
    margin-bottom: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &__info
    margin-bottom: 15px

  &__type
    text-transform: capitalize

  &__info-item
    font-weight: 500
    line-height: 140%
    white-space: nowrap
    color: $gray-300
    overflow: hidden
    text-overflow: ellipsis
    flex: 1 0

    &:not(:last-child)
      margin-right: 4px

  &__info-icon
    width: 24px
    height: 24px
    color: $gray-300

  &__button
    font-weight: 500
    padding: 7px 16px
    color: $gray-400

    &:hover
      background-color: $green-200
      color: $black
      border-color: $green-200
