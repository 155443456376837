.notifications-block
  $this: &

  &:not(:last-child)
    margin-bottom: 16px

  &__title
    display: block
    width: 100%
    padding: 0 24px
    font-size: 16px
    line-height: 140%
    margin-bottom: 16px

    @include r($breakpoint-sm)
      padding: 0 16px

  &__list-item
    padding: 24px 24px 27px 24px
    background-color: transparent
    transition: background-color 300ms ease-in

    @include r($breakpoint-sm)
      padding: 16px 40px 16px 16px

    &:hover
      background-color: $gray-100
      transition: background-color 300ms ease-out

    &.is-new
      background-color: $green-100

  &__activity-title
    max-width: 530px

  &__button
    color: $black
    line-height: 140%
    background-color: transparent
    padding: 8px 24px
    font-weight: 500
    transition: background-color 300ms ease-in

    &:hover
      background-color: $green-100
      transition: background-color 300ms ease-out

    &.is-disabled
      pointer-events: none
      color: $gray-400

  .activity-item-action-menu
    right: 26px

    @include r($breakpoint-sm)
      right: 17px

  .dropdown-menu.show
    left: unset!important
    right: 0!important

  &.is-new
    #{$this}__list
      background-color: $green-100
      padding-top: 12px
      padding-bottom: 12px

      &:empty
        background-color: transparent

    #{$this}__title
      margin-bottom: 12px