.modal-upload-loader
  left: 0
  right: 0
  top: 0
  bottom: 0
  background-color: rgba(255, 255, 255, 70%)

  &__spinner
    width: 2rem
    height: 2rem
    border: 3px solid $gray-300
    border-right-color: transparent
    top: calc(50% - 1rem)
    left: calc(50% - 1rem)
    animation: cssload-spin 700ms infinite linear

@keyframes cssload-spin
  to
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)
      