.phone-validation

  &__countrySelectDropdown
    width: 100%
    margin-bottom: 1rem
    border-radius: 0.25rem
    border: 1px solid #eaeaea

    select
      width: 100%
      height: 41px
      margin-bottom: 0 !important
      padding: 0.47rem 0.75rem
      border: none
      border-right: 16px solid white

  &__icon
    font-size: 26px
    width: 48px
    min-width: 48px
    height: 41px
    border: 1px solid #eaeaea
    border-radius: 8px
    margin-right: 8px
    display: flex
    justify-content: center

  &__input
    input
      border-color: #eaeaea
      height: 41px
      margin-bottom: 0!important

  &__code
    margin-bottom: 0!important
    &:not(:last-child)
      margin-right: 0.5rem
    input
      text-align: center
