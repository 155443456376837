.budget-range-points
  $this: &

  @include r($breakpoint-md)
    display: none

  &__point
    &:first-child
      #{$this}__point-text
        left: -17px

  &__point-text
    top: 12px
    left: -15px
    width: 30px
