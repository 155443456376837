.faq-contact
  padding: 41px 0 26px 0

  &__wrapper
    margin-bottom: 25px

  &__names
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 20px

  &__form
    &.is-error
      .faq-contact__form-input
        border-color: $red

      .faq-contact__form-input
        &.is-focused
          border-color: $primary

  &__form-input
    border-color: $gray-200

    &::placeholder
      color: $gray-400

  &__textarea
    height: 200px
    color: $black
    font-size: 16px
    border-color: $gray-200
    border-radius: 5px
    padding: 6px 12px

    &::placeholder
      color: $gray-400

    &.is-focused
      border-color: $primary

    &.is-error
      border-color: $red

  &__submit
    margin-top: -7px
