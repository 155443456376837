.chat-tasks
  $this: &

  &__list
    margin-bottom: 0

  &__list-item

    &:hover
      #{$this}__button
        background-color: $gray-100

      #{$this}__icon,
      #{$this}__name-text
        color: $green-200

  &__button
    padding: 16px 21px
    line-height: 140%
    color: $black
    background-color: $white
    font-weight: 500

  &__icon
    margin-right: 6px
    color: $gray-300

  &__name
    font-weight: 500
    line-height: 140%

  &__name-text
    text-overflow: ellipsis
    white-space: nowrap

  &__add
    color: $gray-300

    &:hover
      background-color: $gray-100

      #{$this}__add-icon,
      #{$this}__add-text
        color: $green-200

  &__scroll
    max-height: 300px

  &__input-wrapper
    padding: 0 16px 16px

  &__input
    &-input
      font-size: 13px
