.search
  $this: &

  &__form
    height: 34px

    &.m-0
      margin: 0!important

  &__search
    height: 34px

    &::placeholder
      color: $gray-400

  &__search-wrapper
    flex: 1 0
    min-height: 34px
    height: 34px

    &:hover
      #{$this}__search-input
        border-color: $green-300
        transition: border-color 300ms ease-out

    &.is-focused,
    &:hover
      #{$this}__search-icon
        color: $green-300
        transition: color 300ms ease-out

  &__search-input
    padding: 0 10px 0 37px
    border-color: $gray-200
    font-size: 13px

  &__search-icon
    top: 9px
    left: 10px
    color: $gray-400
    transition: color 300ms ease-in

    &::before
      font-size: 16px

  &__remove-icon
    top: 9px
    right: 6px
    color: $gray-400
    transition: color 300ms ease-in
    cursor: pointer

    &::before
      font-size: 16px

    &:hover
      color: $green-300
      transition: color 300ms ease-out

  &__field
    &:not(:last-child)
      margin-right: 12px

    &--type,
    &--budget,
    &--location
      @include r($breakpoint-md)
        display: none

  &__list
    list-style: none

  &__list-item
    &:not(:last-child)
      margin-right: 16px

  &__criteria
    padding-top: 24px

  &__criteria-label
    margin-right: 16px
    color: $gray-400
    line-height: 140%
    font-weight: 500

  &__link
    background-color: $green-200
    border: 0
    color: $black
    justify-content: center
    transition: color 300ms ease-in, background-color 300ms ease-in
    padding: 8px 16px
    font-size: 13px
    line-height: 18.2px
    border-radius: 4px

    &:hover
      color: $black
      background-color: #89CC7F
      transition: color 300ms ease-out, background-color 300ms ease-out


  &__button
    @include r($breakpoint-md)
      display: none!important

  &__link
    @include r($breakpoint-md)
      text-align: center

  &__mobile-button
    display: none
    width: 34px
    height: 34px
    border-radius: 50%
    background-color: $green-200
    transition: background-color 300ms ease-in

    &:hover,
    &:active
      background-color: #89CC7F
      transition: background-color 300ms ease-out

    @include r($breakpoint-md)
      display: flex

  &--inside-modal
    margin-bottom: 0

    .search-select-button
      width: 100%
      justify-content: space-between

    #{$this}__button
      display: flex!important

    #{$this}__mobile-button
      display: none

    #{$this}__form
      flex-direction: column
      height: auto

    #{$this}__select-type,
    #{$this}__select-budget,
    #{$this}__select-location
      width: 100%
      display: block

    #{$this}__field

      &--type,
      &--budget,
      &--location
        display: block

      &:not(:last-child)
        margin-right: 0
        margin-bottom: 12px

  &__wrapper
    @include r($breakpoint-sm)
      padding: 16px
