.chat-right-tab-pane
  $this: &

  &__list
    border-radius: 4px

  &__list-item

    &:hover
      #{$this}__button
        background-color: $gray-100

      #{$this}__button-icon
        color: $green-200

  &__button
    padding: 16px 21px
    line-height: 140%
    color: $black
    grid-template-columns: 1fr auto
    background-color: $white
    width: 100%

  &__button-icon
    margin-right: 6px
    color: $gray-300

  &__name
    font-weight: 500
    line-height: 140%

  &__size
    color: $gray-300
    line-height: 140%
    padding-left: 5px

  &__name-text
    text-overflow: ellipsis
    white-space: nowrap

  &__scroll
    max-height: 300px
    height: auto
    min-height: 16px

  &__download
    width: 0.1px
    height: 0.1px
    opacity: 0
