.search-select-button
  $this: &

  display: flex
  align-items: center
  font-size: 13px
  line-height: 140%
  font-weight: 500
  border: 1px solid $gray-200
  border-radius: 4px
  background-color: transparent
  color: $gray-400
  height: 34px
  padding: 0 12px
  transition: border-color 300ms ease-in

  &:hover,
  &.is-active
    border-color: $green-300
    transition: border-color 300ms ease-out

  &.is-active
    #{$this}__icon
      transform: rotate(180deg)
      opacity: 1
      transition: opacity 300ms ease-out, transform 300ms ease-out

  &__icon
    color: $gray-300
    opacity: 0.5
    width: 18px
    height: 18px
    margin-left: 5px
    transform: rotate(0deg)
    transition: opacity 300ms ease-in, transform 300ms ease-in 

    &:hover
      opacity: 1
      transition: opacity 300ms ease-out