.members
  display: flex
  align-items: flex-start
  padding-right: 23px

  &__item
    display: flex
    margin-right: -20px
    position: relative
    &:before
      content: ''
      display: block
      position: absolute
      top: -3px
      left: -3px
      right: -3px
      bottom: -3px
      border: 3px solid $white
      border-radius: 50%

  &__dots
    position: relative
    margin-left: 15px

  &__dots-button
    color: #fff
    border-radius: 50%
    background-color: rgba(0, 0, 0, 0.45)
    width: 40px
    height: 40px
    display: inline-flex
    align-items: center
    justify-content: center
    position: absolute
    top: 0

  &__icon
    font-size: 21px
