.user-name
  $this: &

  position: relative

  &--size-xs
    max-width: 32px
    min-width: 32px
    height: 32px

  &--size-s
    max-width: 36px
    min-width: 36px
    height: 36px

  &--size-m
    max-width: 40px
    min-width: 40px
    height: 40px

  &--size-l
    max-width: 56px
    min-width: 56px
    height: 56px

  &--size-xxl
    max-width: 184px
    min-width: 184px
    height: 184px

  &::before
    content: ""
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    background-color: $white
    opacity: 0.7
    z-index: 0

  &__text
    position: relative
    font-weight: 600
    z-index: 1
