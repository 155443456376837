.profile-contacts
  flex-direction: column
  align-items: baseline

  &__button
    padding: 0
    color: $green-300
    border: none
    background-color: transparent
    line-height: 22px
    display: block

  &__short-contacts
    color: $gray-400
    margin-right: 16px
    line-height: 22px

  &__modal-header
    border: none
    height: 88px

  &__name
    color: $black
    font-weight: 700

  &__confirm-button
    &.is-disabled
      pointer-events: none
      opacity: 0.5

  &__phone
    &.is-error
      .input-field__input
        border-color: $red