.messages
  padding: 41px 40px 0px
  min-height: calc(100vh - 90px)
  height: calc(100vh - 90px)

  @include r($breakpoint-xxl)
    padding: 41px 24px 0px

  @include r($breakpoint-lg)
    padding-top: 32px

  @include r($breakpoint-sm)
    padding: 0
