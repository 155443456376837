.chat-modal-footer

  &__button
    border: 0

    &.is-disabled
      background-color: $gray-200
      color: $gray-300
      opacity: 1
      pointer-events: none
      border: 0

    &.is-error
      background-color: $red
      color: $white

  &__text
    display: flex
    align-items: center

  &__icon
    font-size: 16px
    margin-right: 6px
