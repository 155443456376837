.chat-right-tab-pane-links
  $this: &

  &__list
    border-radius: 4px

  &__list-item

    &:hover
      #{$this}__link
        background-color: $gray-100

      #{$this}__link-icon
        color: $green-200

  &__link
    padding: 16px 21px !important
    line-height: 140%
    color: $black
    grid-template-columns: 1fr auto

  &__link-icon
    margin-right: 6px
    color: $gray-300

  &__name
    font-weight: 500
    line-height: 140%

  &__size
    color: $gray-300

  &__name-text
    text-overflow: ellipsis
    white-space: nowrap

  &__scroll
    max-height: 300px
    height: auto
    min-height: 16px

  .ogn-container
    display: flex
    padding: 16px

    &:hover
      background-color: $gray-100

  .ogn-image
    width: 40px
    height: 40px
    border-radius: 4px
    margin-right: 8px

  .ogn-content
    overflow: hidden

  .ogn-title
    font-size: 13px
    line-height: 18px
    font-weight: 500
    color: $black
    margin-bottom: 6px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  .ogn-description
    display: none

  .ogn-url
    font-size: 11px
    line-height: 15px
    color: $black
    margin: 0
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
