.experience-skills-section
  background-color: $white
  box-shadow: $box-shadow-100

  &__header
    background-color: $white

  &__title
    color: $black

  &__edit
    position: relative !important
    background-color: $gray-100