.location-select
  $this: &

  &__button
    width: 98px

  &__content
    width: 398px
    height: 94px
    padding: 8px 24px 24px
    z-index: 99
    background-color: $white
    top: 48px
    left: 50%
    transform: translateX(-50%)
    box-shadow: 0px 16px 48px $gray-400
    margin-left: -9px
    border-radius: 4px
    @media (max-width: 744px)
      max-width: 100%

  .places-autocomplete__input-input
    height: 34px
    padding: 0 30px 0 8px

  &__title
    font-size: 13px
    line-height: 140%
    font-weight: 500
    color: $gray-400
    margin-bottom: 10px

  // &__list
  //   list-style: none
  //   padding: 0
  //   display: flex
  //   justify-content: center

  // &__list-item
  //   &:not(:last-child)
  //     margin-right: 8px

  // &__list-button
  //   padding: 8px 14px
  //   margin-top: 4px
  //   margin-bottom: 4px
  //   background-color: $gray-200
  //   transition: background-color 300ms ease-in

  //   &:hover,
  //   &.is-active
  //     background-color: $green-200
  //     transition: background-color 300ms ease-out

  &__input-wrapper
    position: relative
    height: 34px

    &.is-focused
      #{$this}__icon
        color: $green-300

    &:not(:last-child)
      margin-bottom: 22px

  &__icon
    color: $gray-300
    position: absolute
    top: 5px
    right: 8px
    transform: scale(0.7)
    height: 24px
    width: 24px
