.tertiary-icon-button {
  $this: &;

  color: $green-200;
  border: 0;
  background-color: $gray-100;
  transition: color 300ms ease-in, background-color 300ms ease-in;

  &:hover,
  &:focus {
    color: $green-200;
    border: 0;
    background-color: $gray-100;
    transition: background-color 300ms ease-out;
  }

  // states
  &.is-error {
    color: $tomato;
    background-color: #FDEFEF;

    &:hover {
      color: $tomato;
      background-color: #FDEFEF;
    }
  }

  &.is-disabled {
    color: $gray-300;
    background-color: rgba(153, 153, 153, 0.3);
    cursor: default;
    pointer-events: none;
  }
}
