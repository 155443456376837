.close-modal-button
  width: 40px
  height: 40px
  border-radius: 50%
  background-color: $gray-100
  border: none
  color: $gray-400

  &__icon
    width: 12px
    height: 12px
