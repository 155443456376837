.primary-link
  background-color: $green-200
  color: $black
  padding: 13px 20px
  border-radius: 4px
  cursor: pointer
  transition: color 300ms ease-in, background-color 300ms ease-in

  &:hover
    color: $black
    background-color: #89CC7F
    transition: background-color 300ms ease-out

  // states
  &.is-disabled
    background-color: rgba(234, 234, 234, 0.3)
    border: 1px solid $gray-300
    color: $gray-300
    cursor: default
    opacity: 0.7

    &:hover
      background-color: rgba(234, 234, 234, 0.3)

  // sizes
  &--large
    font-size: 18px
    line-height: 22px

  &--medium
    font-size: 16px
    line-height: 22.4px

  &--small,
  &--extra-small
    font-size: 13px
    line-height: 18.2px

  &--small
    padding: 8px 16px

  &--extra-small
    padding: 4px 8px
