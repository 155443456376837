.recommended-users
  background-color: $white
  padding: 24px
  min-width: 306px
  max-width: 306px
  flex: 0 0 auto

  &__scroll
    left: 0px
    right: 0
    position: relative
    top: 0px
    max-height: calc(100% - 40px)
    background: $white
    padding: 24px

  &__title
    line-height: 20px
    color: $black
    background: $white
    height: 38px
    padding: 24px
    margin: 0

  @include r($breakpoint-lg)
    max-width: 100%
    min-width: auto

  &__no-results
    height: auto

  &.is-empty
    height: auto
