.forget-password

  &__login
    bottom: -45px
    left: 0
    right: 0
    margin: 0 auto
    width: max-content
    color: $gray-400
    background-color: transparent
