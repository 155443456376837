.faq-nav
  left: -275px
  position: absolute
  flex-direction: column

  @include r($breakpoint-md)
    display: flex
    position: relative
    top: unset
    left: unset
    flex-direction: row
    width: auto
    flex-wrap: nowrap
    overflow-x: scroll
    padding-left: 16px
    padding-bottom: 16px
    margin-bottom: 16px

  &__item
    &:not(:last-child)
      margin-bottom: 24px

      @include r($breakpoint-md)
        margin: 0 16px 0 0

  &__link
    width: 250px
    height: 127px
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 26px
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)
    background-color: $white!important
    border-radius: 4px!important
    transition: background-color 300ms ease-in

    &:hover
      background-color: $gray-100!important
      transition: background-color 300ms ease-out

    &.active
      background-color: $green-200!important
      color: $black!important

    @include r($breakpoint-md)
      width: auto
      min-width: 147px
      height: 82px
      padding-top: 8px

  &__icon
    font-size: 36px
    line-height: 36px
    margin-bottom: 8px

    @include r($breakpoint-md)
      font-size: 23px
      margin-bottom: 3px

  &__text
    @include r($breakpoint-md)
      white-space: nowrap
