.profile-action-dropdown
  position: absolute
  top: -92px
  right: 24px

  &__toggler
    color: $gray-400
    cursor: pointer
    width: 24px
    height: 24px
    background-color: transparent
    padding: 0
    border: none
    margin-bottom: 7px

    &:hover
      background-color: transparent
      color: $gray-400

  &__item
    font-size: 11px
    height: 31px

  &__menu
    min-width: 109px

  &__icon
    width: 24px
    height: 24px