.share-modal-item
  $this: &

  display: grid
  grid-template-columns: 1fr 1fr
  grid-column-gap: 16px
  padding: 0 22px 0 0
  position: relative

  &:not(:last-child)
    margin-bottom: 17px

  &__remove-icon
    top: 11px
    right: -6px
    color: $gray-400
    transition: color 300ms ease-in
    cursor: pointer

    &::before
      font-size: 16px

    &:hover
      color: $green-300
      transition: color 300ms ease-out

  &__form
    &.is-error
      .share-modal-item__form-input
        border-color: $red

      .share-modal-item__form-input
        &.is-focused
          border-color: $primary

  &__form-input
    border-color: $gray-200

    &::placeholder
      color: $gray-400

