.file-size-modal

  &__header
    border: none
    height: 87px
    justify-content: center

  &__title
    color: $black
    font-weight: 700
