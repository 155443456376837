.share-modal

  &__close
    top: 24px
    right: 24px
    z-index: 1
    height: 40px
    width: 40px
    padding: 0 3px 2px 0px
    transition: transform 300ms ease-in
    transform: scale(1) rotate(0deg)

    @include r($breakpoint-sm)
      right: 0

    &:hover
      transform: scale(1.2) rotate(90deg)
      transition: transform 300ms ease-out

    &::before,
    &::after
      content: ""
      display: block
      background-color: $gray-400
      height: 2px
      width: 20px
      position: absolute
      top: 19px
      left: 10px

    &::before
      transform: rotate(45deg)

    &::after
      transform: rotate(-45deg)

  &__body
    padding: 0 24px 40px

    @include r($breakpoint-lg)
      padding-bottom: 24px

  &__footer
    display: flex
    justify-content: flex-end

  &__list
    margin-bottom: 24px

  &__loader
    height: 300px
    width: 300px
    margin: 38px auto