.notifications
  width: 100%

  &__main
    padding: 24px 0
    width: 100%
    max-width: 908px
    margin: 41px auto
    background-color: $white
    border-radius: 4px

    @include r($breakpoint-lg)
      margin: 0
      padding: 49px 24px 60px 24px
      max-width: 100%

    @include r($breakpoint-sm)
      padding: 17px 0px 24px 0px

  &__title
    font-size: 22px
    font-weight: 700
    line-height: 140%
    margin-bottom: 38px
    padding: 0 24px

    @include r($breakpoint-sm)
    font-size: 18px
    padding: 0 16px
    margin-bottom: 31px
