.profile-page
  padding-top: 0
  margin-top: 49px
  padding-bottom: 0
  margin-bottom: 50px

  @include r($breakpoint-xl)
    justify-content: center
    width: auto
    padding-bottom: 0
    margin-bottom: 50px

  @include r($breakpoint-md)
    min-width: auto
    margin-top: 44px

  @include r($breakpoint-sm)
    margin: 33px 16px 0

  &__users
    min-width: 100%
    overflow: hidden
    box-sizing: border-box
    background: transparent
    padding: 0
    flex: none
    height: 100%

    @include r($breakpoint-xl)
      height: 100%

  &__sidebar
    top: 132px
    left: calc(100% + 24px)
    overflow: hidden
    max-width: 309px
    width: 309px
    top: 0
    bottom: 0
    max-height: unset

    @include r($breakpoint-xl)
      bottom: 0
      max-height: unset
      position: absolute
      left: unset
      right: 0
      top: 0

    @include r($breakpoint-lg)
      display: none

  .profile
    @include r($breakpoint-xl)
      width: auto
      padding-right: 333px

    @include r($breakpoint-lg)
      padding-right: 0
